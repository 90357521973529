require('../../plugins/custom/bootstrap/bootstrap-pincode-input');

jQuery(function() {

    $(function () {
        jQuery.post( xs_ajax_object.ajaxurl, {
            action: 'xs_check_login_ajax',
            security: $('#security').val(),
        }, function(response, status, xhr) {
            $('.login .loading').fadeOut();
        },'json').fail(function(data) {
            $('#loginErrorModal .modal-body').html(data.responseText)
            $('#loginErrorModal').modal('show');
            $('.login .loading').fadeOut();
        });
    });

    $('#loginform').validate({
        messages: {
            'userName': { required:"İstifadəçi adı daxil edilməyib" },
            'passWord': { required:"Şifrə daxil edilməyib" },
        },
        rules: {
            'userName': {
                required: true,
                minlength: 2,
                maxlength: 40,
            },
            'passWord': {
                required: true,
                minlength: 8,
                maxlength: 40,
            },
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
            error.addClass('invalid-feedback');
            element.closest('.form-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).removeClass('is-valid').addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass('is-invalid').addClass('is-valid');
        },
        focusInvalid: true,
        submitHandler: function() {
            $('#loginSubmit').addClass('submitted');
            $('.login .alert').hide();

            var data = {
                action: 'xs_check_login',
                security: $('#security').val(),
                userName: $('input[name=userName]').val(),
                passWord: btoa($('input[name=passWord]').val()),
            };
            jQuery.post( xs_ajax_object.ajaxurl, data, function(response, status, xhr) {
                if( response.success === true ) {
                    $('#user_id').val(response.data.user_id);
                    $('#displayName').html(response.data.display_name);
                    $('#phoneNumber').html(response.data.phone_number);
                    $('#otp').pincodeInput().data('plugin_pincodeInput').clear();
                    $('#timer').countdown('destroy').countdown({
                        onExpiry: function(){
                            $('.expired').fadeIn();
                        }});
                    $('#sms_validation .expired').attr('style', 'display: none !important');
                    $('#smsValidate').removeClass('submitted');
                    $('#loginform').hide();
                    $('#sms_validation').show();

                    if (response.data.otp !== '') {
                        jQuery.fn.xsToast({
                            title: 'Xalq Sığorta',
                            content: 'SMS Kod: ' + response.data.otp,
                            timeoutSeconds: 60,
                        });

                        $('#otp').val(response.data.otp);
                        $('#smsValidate').trigger('click');
                    }

                } else if ( response.success === false ) {
                    $('.login .alert .error-text').html(response.data);
                    $('.login .alert').show().addClass('shake');
                    $('#loginSubmit').removeClass('submitted');
                }
            },'json').fail(function(data) {
                $('#loginErrorModal .modal-body').html(data.responseText)
                $('#loginErrorModal').modal('show');
                $('.login .loading').fadeOut();
            });
        }
    });

    $('.change_user').on('click', function () {
        $('input[name=userName]').val('').removeClass('is-valid');
        $('input[name=passWord]').val('').removeClass('is-valid');
        $('#sms_validation').hide();
        $('#loginSubmit').removeClass('submitted');
        $('#timer').countdown('pause');
        $('.login .alert').hide();
        $('#loginform').fadeIn();
    });

    $('#resend_otp').on('click', function () {
        $('#loginSubmit').removeClass('submitted').trigger('click');
        $('#otp').pincodeInput().data('plugin_pincodeInput').clear();
        $('#timer').countdown('destroy').countdown({
            onExpiry: function(){
                $('.expired').fadeIn();
            }});
        $('#sms_validation .expired').attr('style', 'display: none !important');
        $('#smsValidate').removeClass('submitted');
    });

    $('#otp').pincodeInput({
        inputs:4,
        placeholders:"_ _ _ _",
        // complete: function(value, e, errorElement){
        //     $('#smsValidate').focus();
        // }
    });

    $('.sms-validation input').on('keyup', function () {
        if ($('#otp').val().length === 4) {
            $('#smsValidate').removeClass('disabled').attr('disabled', false).focus();
        } else {
            $('#smsValidate').addClass('disabled').attr('disabled', true);
        }
    });

    $('#smsValidate').on('click', function () {
        if ($('#otp').val().length === 4) {
            $('.login .alert').hide();
            $('#smsValidate').addClass('submitted').addClass('disabled').attr('disabled', true);
            var data = {
                action: 'xs_login',
                security: $('#security').val(),
                userName: $('input[name=userName]').val(),
                passWord: btoa($('input[name=passWord]').val()),
                redirect_to: $('input[name=redirect_to]').val(),
                user_id: $('input[name=user_id]').val(),
                otp: $('#otp').val()
            };
            jQuery.post( xs_ajax_object.ajaxurl, data, function(response, status, xhr) {
                if( response.success === true ) {
                    window.location.href = response.data;
                } else if ( response.success === false ) {
                    $('.login .alert .error-text').html(response.data);
                    $('.login .alert').show().addClass('shake');
                    $('#smsValidate').removeClass('submitted').removeClass('disabled').attr('disabled', false);
                }
            },'json').fail(function(data) {
                $('#loginErrorModal .modal-body').html(data.responseText)
                $('#loginErrorModal').modal('show');
                $('#smsValidate').removeClass('submitted').removeClass('disabled').attr('disabled', false);
            });
        } else {

        }
    });

    $('#clear_cookie').on('click', function (e){
        e.preventDefault();
        document.cookie = 'logged_in_user=; domain=xalqsigorta.az; path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;"';
        $('#autoLoginDiv').remove();
    });

    $('#autoLogin').on('click', function (e){
        var data = {
            action: 'xs_auto_login',
            security: xs_ajax_object.security,
            redirect_to: $('input[name=redirect_to]').val(),
        };
        jQuery.post( xs_ajax_object.ajaxurl, data, function(response, status, xhr) {
            if( response.success === true ) {
                window.location.href = response.data;
            } else if ( response.success === false ) {
                $('.login .alert .error-text').html(response.data);
                $('.login .alert').show().addClass('shake');
            }
        },'json').fail(function(data) {
            $('#loginErrorModal .modal-body').html(data.responseText)
            $('#loginErrorModal').modal('show');
            $('.login .loading').fadeOut();
        });
    });

    $.countdown.setDefaults({
        until: +300,
        format: 'MS',
        timeSeparator: ':',
        compact: true,
    });

});